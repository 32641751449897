import PageHeader from "../../../components/header";
import PageTitle from "../../../components/pageTitle";

const TermsAndConditions = () => {
  return (
    <div>
      <PageHeader isColored />
      <div className="padding-xxxl agreement-wrapper">
        <div className="text-center">
          <PageTitle title="Terms and Conditions" />
        </div>
        <div>
          <p>
            Welcome to EngineerInc.io! These terms and conditions outline the
            rules and regulations for the use of EngineerInc's Website, located
            at <a href="https://engineerinc.io">https://engineerinc.io/</a>.
          </p>
          <br />
          <p>
            By accessing this website, we assume you accept these terms and
            conditions. Do not continue to use EngineerInc.io if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>
          <br />
          <p>
            The following terminology applies to these Terms and Conditions,
            Privacy Statement, and Disclaimer Notice and all Agreements:
            "Client", "You" and "Your" refers to you, the person log on this
            website and compliant to the Company's terms and conditions. "The
            Company", "Ourselves", "We", "Our" and "Us", refers to our Company.
            "Party", "Parties", or "Us", refers to both the Client and
            ourselves. All terms refer to the offer, acceptance, and
            consideration of payment necessary to undertake the process of our
            assistance to the Client in the most appropriate manner for the
            express purpose of meeting the Client's needs in respect of the
            provision of the Company's stated services, in accordance with and
            subject to, prevailing law of Netherlands. Any use of the above
            terminology or other words in the singular, plural, capitalization,
            and/or he/she or they, are taken as interchangeable and therefore as
            referring to the same.
          </p>
        </div>
        <p className="paragraph-title">Cookies</p>
        <p>
          We employ the use of cookies. By accessing EngineerInc.io, you agreed
          to use cookies in agreement with the EngineerInc's Privacy Policy.
        </p>
        <br />
        <p>
          Most interactive websites use cookies to let us retrieve the user's
          details for each visit. Cookies are used by our website to enable the
          functionality of certain areas to make it easier for people visiting
          our website. Some of our affiliate/advertising partners may also use
          cookies.
        </p>
        <p className="paragraph-title">License</p>
        <p>
          Unless otherwise stated, EngineerInc and/or its licensors own the
          intellectual property rights for all material on EngineerInc.io. All
          intellectual property rights are reserved. You may access this from
          EngineerInc.io for your own personal use subjected to restrictions set
          in these terms and conditions.
        </p>
        <br />
        <p>You must not:</p>
        <ul>
          <li>Republish material from EngineerInc.io</li>
          <li>Sell, rent or sub-license material from EngineerInc.io</li>
          <li>Reproduce, duplicate or copy material from EngineerInc.io</li>
          <li>Redistribute content from EngineerInc.io</li>
        </ul>
        <p>This Agreement shall begin on the date hereof.</p>
        <br />
        <p>
          Parts of this website offer an opportunity for users to post and
          exchange opinions and information in certain areas of the website.
          EngineerInc does not filter, edit, publish, or review Comments prior
          to their presence on the website. Comments do not reflect the views
          and opinions of EngineerInc, its agents, and/or affiliates. Comments
          reflect the views and opinions of the person who posts their views and
          opinions. To the extent permitted by applicable laws, EngineerInc
          shall not be liable for the Comments or for any liability, damages, or
          expenses caused and/or suffered as a result of any use of and/or
          posting of and/or appearance of the Comments on this website.
        </p>
        <br />
        <p>
          EngineerInc reserves the right to monitor all Comments and to remove
          any Comments which can be considered inappropriate, offensive, or
          causes breach of these Terms and Conditions.
        </p>
        <br />
        <p>You warrant and represent that:</p>
        <ul>
          <li>
            You are entitled to post the Comments on our website and have all
            necessary licenses and consents to do so
          </li>
          <li>
            The Comments do not invade any intellectual property right,
            including without limitation copyright, patent, or trademark of any
            third party
          </li>
          <li>
            The Comments do not contain any defamatory, libelous, offensive,
            indecent, or otherwise unlawful material which is an invasion of
            privacy
          </li>
          <li>
            The Comments will not be used to solicit or promote business or
            custom or present commercial activities or unlawful activity
          </li>
        </ul>
        <p>
          You hereby grant EngineerInc a non-exclusive license to use,
          reproduce, edit, and authorize others to use, reproduce, and edit any
          of your Comments in any and all forms, formats, or media.
        </p>
        <p className="paragraph-title">Reservation of Rights</p>
        <p>
          We reserve the right to request that you remove all links or any
          particular link to our Website. You approve to immediately remove all
          links to our Website upon request. We also reserve the right to amend
          these terms and conditions and its linking policy at any time. By
          continuously linking to our Website, you agree to be bound to and
          follow these linking terms and conditions.
        </p>
        <p className="paragraph-title">Removal of links from our website</p>
        <p>
          If you find any link on our Website that is offensive for any reason,
          you are free to contact and inform us at any moment. We will consider
          requests to remove links but we are not obligated to or so or to
          respond to you directly.
        </p>
        <br />
        <p>
          We do not ensure that the information on this website is correct, we
          do not warrant its completeness or accuracy; nor do we promise to
          ensure that the website remains available or that the material on the
          website is kept up to date.
        </p>
        <p className="paragraph-title">Disclaimer</p>
        <p>
          To the maximum extent permitted by applicable law, we exclude all
          representations, warranties, and conditions relating to our website
          and the use of this website. Nothing in this disclaimer will:
        </p>
        <ul>
          <li>
            Limit or exclude our or your liability for death or personal injury
          </li>
          <li>
            Limit or exclude our or your liability for fraud or fraudulent
            misrepresentation
          </li>
          <li>
            Limit any of our or your liabilities in any way that is not
            permitted under applicable law or
          </li>
          <li>
            Exclude any of our or your liabilities that may not be excluded
            under applicable law
          </li>
        </ul>
        <p>
          The limitations and prohibitions of liability set in this Section and
          elsewhere in this disclaimer: (a) are subject to the preceding
          paragraph; and (b) govern all liabilities arising under the
          disclaimer, including liabilities arising in contract, in tort, and
          for breach of statutory duty.
        </p>
        <br />
        <p>
          As long as the website and the information and services on the website
          are provided free of charge, we will not be liable for any loss or
          damage of any nature.
        </p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
