import PageHeader from "../../../components/header";
import PageTitle from "../../../components/pageTitle";

const PrivacyPolicy = () => {
  return (
    <div>
      <PageHeader isColored/>
      <div className="padding-xxxl agreement-wrapper">
        <div className="text-center">
          <PageTitle title="Privacy Policy" />
        </div>
        <div>
          <p>
            At EngineerInc.io, accessible from 
            <a href="https://engineerinc.io">https://engineerinc.io/</a>, one of
            our main priorities is the privacy of our visitors. This Privacy
            Policy document contains types of information that is collected and
            recorded by EngineerInc.io and how we use it.
          </p>
          <br />
          <p>
            If you have additional questions or require more information about
            our Privacy Policy, do not hesitate to contact us through email
            at new@engineerinc.io.
          </p>
          <br />
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collect in EngineerInc.io. This policy is
            not applicable to any information collected offline or via channels
            other than this website.
          </p>
        </div>
        <p className="paragraph-title">Consent</p>
        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>
        <p className="paragraph-title">Information we collect</p>
        <p>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <br />
        <p>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <br />
        <p>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>
        <p className="paragraph-title">How we use your information</p>
        <p>We use the information we collect in various ways, including to:</p>
        <br />
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
