import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Flex, Layout } from "antd";
import { useWindowSize } from "../../hooks/useWindowSize.js";
import { privateMenuItems } from "../../utils/menuItems.js";
import { selectUser } from "../../redux/slices/userSlice.js.js";
import VerticalMenu from "../menu/VerticalMenu.js";
import AuthMenu from "../menu";
import Logo from "../logo";
import "./styles.css";

const { Header } = Layout;

const PageHeader = ({ isColored = false }) => {
  const { role } = useSelector(selectUser);
  const { isMobile } = useWindowSize();
  const accessToken = localStorage.getItem("accessToken");

  const renderLogo = () => {
    if (!accessToken || isMobile) {
      return (
        <Link to="/projects">
          <Logo isColored={isColored} />
        </Link>
      );
    }
  };

  return (
    <Header className={accessToken ? "page-header" : "page-header-auth"}>
      <Flex align="center" justify="center" gap={16}>
        {renderLogo()}
        {isMobile && accessToken && (
          <VerticalMenu menuItems={privateMenuItems(role)} />
        )}
      </Flex>
      {!accessToken && <AuthMenu />}
    </Header>
  );
};

export default PageHeader;
