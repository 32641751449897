import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { ConfigProvider, Flex } from "antd";
import store from "./redux/store";
// import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/auth/components/ForgotPassword";
import PageNotFound from "./components/pageNotFount";
import ForgotPasswordForm from "./pages/auth/components/ForgotPasswordForm";
import UpdatePasswordForm from "./pages/auth/UpdatePasswordForm";
import UpdatePasswordResult from "./pages/auth/UpdatePasswordResult";
import DoVerifyInfo from "./pages/auth/DoVerifyInfo";
import VerifyEmailResult from "./pages/auth/VerifyEmailResult";
import PrivateRoutes from "./router/PrivateRoutes";
import PageLayout from "./components/layouts/PageLayout";
import AuthLayout from "./components/layouts/AuthLayout";
import Contractors from "./pages/contractors";
import Projects from "./pages/projects";
import Project from "./pages/projects/Project";
import PaymentMethods from "./pages/paymentMethods";
import Drawing from "./pages/projects/components/Drawing";
import Revisions from "./pages/projects/components/Revisions";
import ProjectDetails from "./pages/projects/components/ProjectDetails";
import RevisionDetails from "./pages/projects/components/RevisionDetails";
import PrivacyPolicy from "./pages/auth/components/PrivacyPolicy";
import TermsAndConditions from "./pages/auth/components/TermsAndConditions";
import Transactions from "./pages/paymentMethods/Transactions";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  globalStyles,
  buttonStyles,
  menuStyles,
  tableStyles,
  resultStyles,
  badgeStyles,
} from "./styles/theme";
import "./App.css";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT}>
          <ConfigProvider
            theme={{
              components: {
                Result: resultStyles,
                Button: buttonStyles,
                Menu: menuStyles,
                Table: tableStyles,
                Badge: badgeStyles,
              },
              token: globalStyles,
            }}
          >
            <div className="app">
              <Routes>
                <Route element={<PrivateRoutes />}>
                  <Route path="/" element={<PageLayout />}>
                    <Route index element={<Projects submitted />} />
                    <Route path="projects" element={<Projects submitted />} />
                    <Route path="profile" element={<Profile />} />
                    <Route
                      path="contractors/active"
                      element={<Contractors />}
                    />
                    <Route
                      path="contractors/deactive"
                      element={<Contractors deactivated />}
                    />
                    <Route
                      path="/contractors/:contractorId"
                      element={<Profile />}
                    />
                    <Route
                      path="projects/drafts"
                      element={<Projects submitted={false} />}
                    />
                    <Route path="projects/:draftId" element={<Project />}>
                      <Route path="details" element={<ProjectDetails />} />
                      <Route path="drawing" element={<Drawing />} />
                      <Route path="revisions" element={<Revisions />} />
                      <Route path="*" element={<PageNotFound />} />
                    </Route>
                    <Route
                      path="revisions/:revisionId"
                      element={<RevisionDetails />}
                    />
                    <Route
                      path="payment-methods/drafts/:draftId"
                      element={<PaymentMethods pageTitle="Checkout" />}
                    />
                    <Route
                      path="payment-methods/revisions/:revisionId"
                      element={<PaymentMethods pageTitle="Checkout" />}
                    />
                    <Route
                      path="payment-methods"
                      element={<PaymentMethods pageTitle="Credit Cards" />}
                    />
                    <Route path="transactions" element={<Transactions />} />
                    <Route path="payments" element={<Transactions />} />
                  </Route>
                </Route>
                <Route element={<AuthLayout />}>
                  <Route element={<Register />} path="sign-up" />
                  <Route element={<Login />} path="sign-in" />
                  <Route element={<ForgotPassword />}>
                    <Route
                      element={<ForgotPasswordForm />}
                      path="forgot-password"
                    />
                    <Route
                      element={<UpdatePasswordForm />}
                      path="update-password-verify"
                    />
                  </Route>
                  <Route element={<DoVerifyInfo />} path="verify-email" />
                  <Route path="*" element={<PageNotFound />} />
                  <Route
                    element={<VerifyEmailResult />}
                    path="sign-up-verify"
                  />
                  <Route
                    element={<UpdatePasswordResult />}
                    path="update-password-result"
                  />
                </Route>
                <Route element={<PrivacyPolicy />} path="privacy-policy" />
                <Route
                  element={<TermsAndConditions />}
                  path="terms-and-conditions"
                />
                <Route path="*" element={<PageNotFound />} />
              </Routes>
            </div>
          </ConfigProvider>
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  );
};
export default App;
