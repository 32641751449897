import { useState } from "react";
import { EditOutlined, FileImageOutlined } from "@ant-design/icons";
import { AsyncImage } from "loadable-image";
import CompanyProfileForm from "./CompanyProfileForm.js";
import IconWithText from "../../../components/iconWithText";
import CustomButton from "../../../components/button/index.js";
import CustomDrawer from "../../../components/drawer/index.js";
import NewInfoCard from "../../../components/infoCard/NewInfoCard.js";
import CustomCard from "../../../components/card/index.js";
import isEmpty from "lodash/isEmpty";
import "../styles.css";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const fields = {
  logo: {
    visible: true,
    render: ({ logo }) => {
      return (
        <div className="info-logo-wrapper">
          {!!logo ? (
            <AsyncImage
              className="info-logo"
              src={logo.startsWith("blob") ? logo : API_BASE_URL + logo}
              loader={<div className="info-logo-placeholder" />}
              error={<div style={{ background: "#222" }} />}
            />
          ) : (
            <FileImageOutlined className="info-logo info-logo-icon" />
          )}
        </div>
      );
    },
  },
  name: {
    label: "Company",
  },
  address: {
    label: "Address",
  },
  city: {
    label: "City",
  },
  state: {
    label: "State",
  },
  zipCode: {
    label: "Zip Code",
  },
  licenseType: {
    label: "License Type",
  },
  licenseNumber: {
    label: "License Number",
  },
};
const CompanyProfileInfo = ({ company }) => {
  const [modalOpen, setModalOpen] = useState(false);
  return (
    <>
      {!isEmpty(company) ? (
        <NewInfoCard
          item={company}
          fields={fields}
          renderActions={[
            <div className="is-link" onClick={() => setModalOpen(true)}>
              <IconWithText
                text="Edit Company"
                IconComponent={EditOutlined}
                styles={{ justifyContent: "center" }}
              />
            </div>,
          ]}
        />
      ) : (
        <CustomCard
          customClass="no-company-info-container padding-xl"
          description={
            <div className="no-company">
              <p className="margin-bottom-xl text-black text-lg">
                Provide company details
              </p>
              <CustomButton
                buttonTitle="Create"
                onClick={() => setModalOpen(true)}
              />
            </div>
          }
        />
      )}
      <CustomDrawer
        open={modalOpen}
        onCancel={() => setModalOpen(false)}
        title="Edit Company"
        content={<CompanyProfileForm onCancel={() => setModalOpen(false)} />}
      />
    </>
  );
};

export default CompanyProfileInfo;
